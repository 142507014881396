import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/context";
import { getCertificatesByIssuer } from "../../helpers/functions";
import Card from "../Card";

export default function IssuedCertificate() {
  const { Contract, issuerData } = useContext(GlobalContext);
  const [certificates, setCertificates] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const issuedCertificates = await getCertificatesByIssuer(Contract);
        setCertificates(issuedCertificates);
      } catch (error) {
        console.error(error.message);
      }
    })();
  }, []);

  return (
    <section className="w-full py-12 bg-white lg:py-24">
      <div className="max-w-6xl px-12 mx-auto text-center">
        <div className="space-y-12 md:text-center"></div>

        <div className="grid grid-cols-1 gap-10 sm:grid-cols-2 lg:grid-cols-3">
          {certificates.length ? (
            certificates.map((item, i) => {
              return item && <Card data={item} key={i} />;
            })
          ) : (
            <>No Records Found</>
          )}
        </div>
      </div>
    </section>
  );
}
