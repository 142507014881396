import React, { useContext, useState, useEffect } from "react";
import { createUser } from "../helpers/functions";
import { GlobalContext } from "../context/context";

// eslint-disable-next-line react/prop-types
export default function SignUpForm({ closeModal }) {
  // get form data in react hooks
  const [formData, setFormData] = useState({
    publickKey: "",
    city: "",
    name: "",
    gender: "",
    age: ""
  });
  const { Contract, accounts } = useContext(GlobalContext);

  // create a form in react hooks
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    console.log(accounts[0]);
    setFormData({ ...formData, publickKey: accounts[0] });
  }, [accounts]);

  // create a form in react hooks
  const handleSubmit = async (e) => {
    e.preventDefault();
    await createUser(Contract, formData);
    closeModal();
  };

  return (
    <form className="space-y-5" onSubmit={handleSubmit}>
      {[
        { name: "publickKey", label: "Public key", disabled: true },
        { name: "city", label: "City" },
        { name: "name", label: "Name" },
        { name: "gender", label: "Gender" },
        { name: "age", label: "Age" }
      ].map((item, index) => (
        <div key={index}>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700">
            {item.label}
          </label>
          <div className="mt-1">
            <input
              id={item.name}
              name={item.name}
              disabled={item.disabled}
              value={formData[item.name]}
              onChange={handleChange}
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </div>
      ))}

      <div>
        <button
          type="submit"
          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Sign Up
        </button>
      </div>
    </form>
  );
}
