import React, { useContext, useState, useEffect } from "react";
import { GlobalContext } from "../../context/context";
import moment from "moment";

export default function IssueNewCertificate() {
  const { Contract, issuerData } = useContext(GlobalContext);
  const [admissionNo, setAdmissionNo] = useState("");
  const [hospital, setHospital] = useState("");
  const [doctor, setDoctor] = useState("");
  const [therapeuticArea, setTherapeuticArea] = useState("");
  const [city, setCity] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [userPublicKey, setUserPublicKey] = useState("");
  const [expiresAt, setExpiresAt] = useState("");
  const [issuerPublicKey, setIssuerPublicKey] = useState("");

  useEffect(() => {
    if (issuerData && issuerData.isActive) {
      setIssuerPublicKey(issuerData.publicKey);
      setExpiresAt(2022022);
    }
  }, []);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const data = {
      admissionNo,
      hospital,
      doctor,
      therapeuticArea,
      city,
      name,
      gender,
      age,
      userPublicKey,
      expiresAt,
      issuerPublicKey
    };

    await issueCertificate(data);
    setAdmissionNo("");
    setHospital("");
    setDoctor("");
    setTherapeuticArea("");
    setCity("");
    setName("");
    setGender("");
    setAge("");
    setUserPublicKey("");
    setExpiresAt("");
  };

  const issueCertificate = async (data) => {
    try {
      const { admissionNo, hospital, doctor, therapeuticArea, city, name, gender, age, userPublicKey, expiresAt, issuerPublicKey } = data;
      await Contract.addRecord(admissionNo, hospital, doctor, therapeuticArea, city, name, gender, age, userPublicKey, expiresAt);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form className="space-y-8 divide-y divide-gray-200 p-10" onSubmit={handleSubmitForm}>
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Upload New Record</h3>
            {/* <p className="mt-1 text-sm text-gray-500">This information will be displayed publicly so be careful what you share.</p> */}
          </div>
        </div>

        {/** Personal Info */}
        <div className="pt-8">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Record Details</h3>
            {/* <p className="mt-1 text-sm text-gray-500">Please mention your social id links</p> */}
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="admissionNo" className="block text-sm font-medium text-gray-700">
                Admission No
              </label>
              <div className="mt-1">
                <input
                  onChange={(e) => setAdmissionNo(e.target.value)}
                  type="text"
                  name="admissionNo"
                  value={admissionNo}
                  id="admissionNo"
                  autoComplete="given-name"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="hospital" className="block text-sm font-medium text-gray-700">
                Hospital
              </label>
              <div className="mt-1">
                <input
                  onChange={(e) => setHospital(e.target.value)}
                  type="text"
                  value={hospital}
                  name="hospital"
                  id="hospital"
                  autoComplete="family-name"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="doctor" className="block text-sm font-medium text-gray-700">
                Doctor
              </label>
              <div className="mt-1">
                <input
                  onChange={(e) => setDoctor(e.target.value)}
                  type="text"
                  value={doctor}
                  name="doctor"
                  id="doctor"
                  autoComplete="family-name"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="therapeuticArea" className="block text-sm font-medium text-gray-700">
                Therapeutic Area
              </label>
              <div className="mt-1">
                <input
                  onChange={(e) => setTherapeuticArea(e.target.value)}
                  type="text"
                  value={therapeuticArea}
                  name="therapeuticArea"
                  id="therapeuticArea"
                  autoComplete="family-name"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                City
              </label>
              <div className="mt-1">
                <input
                  onChange={(e) => setCity(e.target.value)}
                  type="text"
                  value={city}
                  name="city"
                  id="city"
                  autoComplete="family-name"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <div className="mt-1">
                <input
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  value={name}
                  name="name"
                  id="name"
                  autoComplete="family-name"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
                Gender
              </label>
              <div className="mt-1">
                <input
                  onChange={(e) => setGender(e.target.value)}
                  type="text"
                  value={gender}
                  name="gender"
                  id="gender"
                  autoComplete="family-name"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="age" className="block text-sm font-medium text-gray-700">
                Age
              </label>
              <div className="mt-1">
                <input
                  onChange={(e) => setAge(e.target.value)}
                  type="text"
                  value={age}
                  name="age"
                  id="age"
                  autoComplete="family-name"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="userPublicKey" className="block text-sm font-medium text-gray-700">
                User Public Key
              </label>
              <div className="mt-1">
                <input
                  onChange={(e) => setUserPublicKey(e.target.value)}
                  type="text"
                  value={userPublicKey}
                  name="userPublicKey"
                  id="userPublicKey"
                  autoComplete="family-name"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="expiresAt" className="block text-sm font-medium text-gray-700">
                Expires At
              </label>
              <div className="mt-1">
                <input
                  onChange={(e) => setExpiresAt(e.target.value)}
                  type="text"
                  value={expiresAt}
                  disabled
                  name="expiresAt"
                  id="expiresAt"
                  autoComplete="family-name"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            Issue
          </button>
        </div>
      </div>
    </form>
  );
}
