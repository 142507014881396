/* eslint-disable react/prop-types */
import React, { useState, useContext, useEffect } from "react";
import { GlobalContext } from "../context/context";

const Certificate = (props) => {
  const [cert, setCert] = useState(null);
  const { accounts } = useContext(GlobalContext);
  useEffect(() => {
    (async () => {
      try {
        setCert(props.data[0]);
      } catch (error) {
        if (!error.message.includes("No User Found")) alert(error);
      }
    })();
  }, []);

  return (
    <>
      {cert && Object.keys(cert).length !== 0 && (
        <div className="overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">Patient Information</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Full Name</dt>
                <dd className="mt-1 text-sm text-gray-900">{cert.name}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Admission No</dt>
                <dd className="mt-1 text-sm text-gray-900">{cert.admissionNo}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">City</dt>
                <dd className="mt-1 text-sm text-gray-900">{cert.city}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Age</dt>
                <dd className="mt-1 text-sm text-gray-900">{cert.age}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Therapeutic Area</dt>
                <dd className="mt-1 text-sm text-gray-900">{cert.therapeuticArea}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Hospital</dt>
                <dd className="mt-1 text-sm text-gray-900">{cert.hospital}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Doctor</dt>
                <dd className="mt-1 text-sm text-gray-900">{cert.doctor}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Issuer Public Key</dt>
                <dd className="mt-1 text-sm text-gray-900">{cert.issuerPublicKey}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Is Owner</dt>
                <dd className="mt-1 text-sm text-gray-900">{cert.userPublicKey === accounts[0] ? "YES" : "NO"}</dd>
              </div>
            </dl>
          </div>
        </div>
      )}
    </>
  );
};

export default Certificate;
