const config = {
  DEPLOYED_CONTRACT: {
    ROPSTEN: {
      CONTRACT_ADDRESS: "0x84CBDF578472cB07ea5BA4dFA5a319155253BFD0",
      ABI: [
        {
          inputs: [],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          inputs: [
            {
              internalType: "string",
              name: "",
              type: "string",
            },
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          name: "accessList",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_publicKey",
              type: "address",
            },
            {
              internalType: "string",
              name: "_typeOfIssuer",
              type: "string",
            },
            {
              internalType: "string",
              name: "_org",
              type: "string",
            },
            {
              internalType: "string",
              name: "_name",
              type: "string",
            },
          ],
          name: "addIssuer",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "string",
              name: "_admissionNumber",
              type: "string",
            },
            {
              internalType: "string",
              name: "_hospital",
              type: "string",
            },
            {
              internalType: "string",
              name: "_doctor",
              type: "string",
            },
            {
              internalType: "string",
              name: "_therapeuticArea",
              type: "string",
            },
            {
              internalType: "string",
              name: "_city",
              type: "string",
            },
            {
              internalType: "string",
              name: "_name",
              type: "string",
            },
            {
              internalType: "string",
              name: "_gender",
              type: "string",
            },
            {
              internalType: "uint8",
              name: "_age",
              type: "uint8",
            },
            {
              internalType: "address",
              name: "_publicKey",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "_expiresAt",
              type: "uint256",
            },
          ],
          name: "addRecord",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_publicKey",
              type: "address",
            },
            {
              internalType: "string",
              name: "_city",
              type: "string",
            },
            {
              internalType: "string",
              name: "_name",
              type: "string",
            },
            {
              internalType: "string",
              name: "_gender",
              type: "string",
            },
            {
              internalType: "uint8",
              name: "_age",
              type: "uint8",
            },
          ],
          name: "addUser",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_address",
              type: "address",
            },
          ],
          name: "getIssuerData",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
            {
              internalType: "string",
              name: "",
              type: "string",
            },
            {
              internalType: "string",
              name: "",
              type: "string",
            },
            {
              internalType: "string",
              name: "",
              type: "string",
            },
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "getRecordsByIssuer",
          outputs: [
            {
              components: [
                {
                  internalType: "string",
                  name: "admissionNo",
                  type: "string",
                },
                {
                  internalType: "string",
                  name: "hospital",
                  type: "string",
                },
                {
                  internalType: "string",
                  name: "doctor",
                  type: "string",
                },
                {
                  internalType: "string",
                  name: "therapeuticArea",
                  type: "string",
                },
                {
                  internalType: "string",
                  name: "city",
                  type: "string",
                },
                {
                  internalType: "string",
                  name: "name",
                  type: "string",
                },
                {
                  internalType: "string",
                  name: "gender",
                  type: "string",
                },
                {
                  internalType: "uint8",
                  name: "age",
                  type: "uint8",
                },
                {
                  internalType: "uint256",
                  name: "timestamp",
                  type: "uint256",
                },
                {
                  internalType: "address",
                  name: "userPublicKey",
                  type: "address",
                },
                {
                  internalType: "address",
                  name: "issuerPublicKey",
                  type: "address",
                },
                {
                  internalType: "uint256",
                  name: "expiresAt",
                  type: "uint256",
                },
              ],
              internalType: "struct PatientRecordContract.Record[]",
              name: "",
              type: "tuple[]",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "_address",
              type: "address",
            },
          ],
          name: "getUserData",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
            {
              internalType: "string",
              name: "",
              type: "string",
            },
            {
              internalType: "string",
              name: "",
              type: "string",
            },
            {
              internalType: "string",
              name: "",
              type: "string",
            },
            {
              internalType: "uint8",
              name: "",
              type: "uint8",
            },
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          name: "issuers",
          outputs: [
            {
              internalType: "address",
              name: "publicKey",
              type: "address",
            },
            {
              internalType: "string",
              name: "typeOfIssuer",
              type: "string",
            },
            {
              internalType: "string",
              name: "org",
              type: "string",
            },
            {
              internalType: "string",
              name: "name",
              type: "string",
            },
            {
              internalType: "bool",
              name: "isApproved",
              type: "bool",
            },
            {
              internalType: "bool",
              name: "isActive",
              type: "bool",
            },
            {
              internalType: "bool",
              name: "isCreated",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [],
          name: "owner",
          outputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "uint256",
              name: "",
              type: "uint256",
            },
          ],
          name: "recordsArray",
          outputs: [
            {
              internalType: "string",
              name: "admissionNo",
              type: "string",
            },
            {
              internalType: "string",
              name: "hospital",
              type: "string",
            },
            {
              internalType: "string",
              name: "doctor",
              type: "string",
            },
            {
              internalType: "string",
              name: "therapeuticArea",
              type: "string",
            },
            {
              internalType: "string",
              name: "city",
              type: "string",
            },
            {
              internalType: "string",
              name: "name",
              type: "string",
            },
            {
              internalType: "string",
              name: "gender",
              type: "string",
            },
            {
              internalType: "uint8",
              name: "age",
              type: "uint8",
            },
            {
              internalType: "uint256",
              name: "timestamp",
              type: "uint256",
            },
            {
              internalType: "address",
              name: "userPublicKey",
              type: "address",
            },
            {
              internalType: "address",
              name: "issuerPublicKey",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "expiresAt",
              type: "uint256",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "string",
              name: "_admissionNo",
              type: "string",
            },
            {
              internalType: "address",
              name: "_userId",
              type: "address",
            },
          ],
          name: "revokeSharedRecord",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "string",
              name: "_admissionNo",
              type: "string",
            },
            {
              internalType: "address",
              name: "_userId",
              type: "address",
            },
          ],
          name: "shareRecord",
          outputs: [
            {
              internalType: "bool",
              name: "",
              type: "bool",
            },
          ],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [],
          name: "totalRecordsForUser",
          outputs: [
            {
              components: [
                {
                  internalType: "string",
                  name: "admissionNo",
                  type: "string",
                },
                {
                  internalType: "string",
                  name: "hospital",
                  type: "string",
                },
                {
                  internalType: "string",
                  name: "doctor",
                  type: "string",
                },
                {
                  internalType: "string",
                  name: "therapeuticArea",
                  type: "string",
                },
                {
                  internalType: "string",
                  name: "city",
                  type: "string",
                },
                {
                  internalType: "string",
                  name: "name",
                  type: "string",
                },
                {
                  internalType: "string",
                  name: "gender",
                  type: "string",
                },
                {
                  internalType: "uint8",
                  name: "age",
                  type: "uint8",
                },
                {
                  internalType: "uint256",
                  name: "timestamp",
                  type: "uint256",
                },
                {
                  internalType: "address",
                  name: "userPublicKey",
                  type: "address",
                },
                {
                  internalType: "address",
                  name: "issuerPublicKey",
                  type: "address",
                },
                {
                  internalType: "uint256",
                  name: "expiresAt",
                  type: "uint256",
                },
              ],
              internalType: "struct PatientRecordContract.Record[]",
              name: "",
              type: "tuple[]",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address",
              name: "",
              type: "address",
            },
          ],
          name: "users",
          outputs: [
            {
              internalType: "address",
              name: "publicKey",
              type: "address",
            },
            {
              internalType: "string",
              name: "city",
              type: "string",
            },
            {
              internalType: "string",
              name: "name",
              type: "string",
            },
            {
              internalType: "string",
              name: "gender",
              type: "string",
            },
            {
              internalType: "uint8",
              name: "age",
              type: "uint8",
            },
            {
              internalType: "bool",
              name: "isCreated",
              type: "bool",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
      ],
    },
    network: "ropsten",
  },
};

export default config;
